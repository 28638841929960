import type { ApiModelAttributes, ApiModelEmbeds } from '../api.model'
import { ApiModel } from '../api.model'
import {
    ApiBaseUrlBuilder,
    type ApiFilter,
    ApiFilterOperation,
    type ApiFilterValue,
    ApiSortDirection,
    type ApiUrlParameterValue,
    ApiUrlParameterName,
    createFilter,
    type ApiUrlParameters,
    type ApiEmbedFieldData,
    type EmbedParameters,
    type EmbedOptions,
    type EmbedParametersMap,
    type ApiFilterKey
} from './api.base-url-builder'
import {
    type ComputedRef,
    type MaybeRefOrGetter,
    type Ref,
    ref,
    computed,
    toValue,
    isReactive,
    isRef,
    getCurrentScope
} from 'vue'
import { joinURL } from 'ufo'
import { Base64 } from 'js-base64'

interface ApiReactiveUrlBuilderBaseOptions {}

type ApiReactiveUrlBuilderOptions = {
    url: MaybeRefOrGetter<string>
} & ApiReactiveUrlBuilderBaseOptions

type BuilderData<M extends ApiModel> = {
    _params: Ref<Partial<{ [key in (ApiUrlParameterName & string)]: MaybeRefOrGetter<ApiUrlParameterValue> | ComputedRef<ApiUrlParameterValue> }>>
    _filters: Ref<{ f: string[], o: ApiFilter['o'], v: MaybeRefOrGetter<ApiFilter['v']>, options: Partial<FilterOptions> }[]>
    _sort: ComputedRef<{ attr: keyof ApiModelAttributes<M>, dir: ApiSortDirection }[]> | null
    _embeds: Ref<ApiEmbedFieldData<M>>
    // ---
    _url: MaybeRefOrGetter<string>
    _routeParams: Ref<MaybeRefOrGetter<string>[]>
    _id: MaybeRefOrGetter<string | number | null | undefined> | null
    // ---
    /**
     * The built URL with the ID appended if it's present.
     * This URL doesn't include the query parameters.
     */
    builtUrl: ComputedRef<string>
    /**
     * The built query parameters in the form of an object.
     */
    builtParams: ComputedRef<Partial<ApiUrlParameters>>
}

interface FilterOptions {
}

export class ApiReactiveUrlBuilder<M extends ApiModel> extends ApiBaseUrlBuilder<M> {
    private readonly options: ApiReactiveUrlBuilderOptions
    private builderData: BuilderData<M>

    constructor(options: ApiReactiveUrlBuilderOptions) {
        if (!getCurrentScope()) {
            throw new Error('Reactive ApiUrlBuilder can only be used within the script setup block of a component. ' +
                'You can use the non-reactive ApiUrlBuilder in other places.')
        }
        super()

        this.options = options
        this.builderData = this.generateBuilderData({
            url: options.url,
        })
    }

    private addFilter(column: keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[], operation: ApiFilter['o'], value: MaybeRefOrGetter<ApiFilterValue>, options: Partial<FilterOptions> | undefined) {
        this.builderData._filters.value.push({ f: (Array.isArray(column) ? column : [column]) as string[], o: operation, v: value, options: options || {} })
    }

    private generateBuilderData(options: ApiReactiveUrlBuilderOptions) {
        // TODO: dispose of effects
        const data: BuilderData<M> = {
            _params: ref({}),
            _filters: ref([]),
            _sort: null,
            _embeds: ref({}),
            // ---
            _url: options.url,
            _routeParams: ref([]),
            _id: null,
            // ---
            builtUrl: computed(() => {
                return joinURL(
                    toValue(data._url),
                    ...toValue(data._routeParams).map(
                        part => toValue(part)
                    ),
                    data._id !== null && data._id !== undefined ? `${(toValue(data._id) ?? '')}` : ''
                )
            }),
            builtParams: computed(() => {
                const params: Partial<ApiUrlParameters> = {}

                // keyed parameters
                for (const key in data._params.value) {
                    const paramValue = toValue(data._params.value[key as keyof typeof data._params.value])
                    params[key] = typeof paramValue === 'boolean' ? paramValue ? 1 : 0 : paramValue
                }

                // filter parameters
                const filters = data._filters.value.filter(filter => toValue(filter.v) !== undefined)
                if (filters.length) {
                    params[ApiUrlParameterName.FILTERS] = Base64.encode(
                        JSON.stringify(
                            filters.map(filter =>
                                createFilter(filter.f, filter.o, toValue(filter.v)))
                        )
                    )
                }

                // sort parameters
                if (data._sort) {
                    params[ApiUrlParameterName.SORT_OPTIONS] = data._sort.value.map(sort =>
                        `${sort.dir === ApiSortDirection.DESCENDING ? '-' : ''}${sort.attr as string}`
                    ).join(',') || undefined
                }

                // embed parameters
                const embeds: string[] = []
                for (const key in data._embeds.value) {
                    // only params
                    const only = data._embeds.value[key]?.options.only ?? []
                    const onlyStr = only.length ? `{${only.join(',')}}` : ''
                    // push the embed
                    embeds.push(`${key}${onlyStr}`)
                }
                if (embeds.length) {
                    params[ApiUrlParameterName.EMBED_FIELDS] = embeds.join(',')
                }

                return params
            }),
        }
        return data
    }

    /**
     * Get the reactive data of the builder and reset it.
     * This is used to capture the current state of the builder for a specific reactive fetch service
     * and reset it for other request configurations on the same builder instance.
     * @protected
     */
    protected captureBuilderDataAndReset(): BuilderData<M> {
        const data = this.builderData
        this.builderData = this.generateBuilderData(this.options)
        return data
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Public API

    setPage(index: MaybeRefOrGetter<number>): this {
        this.builderData._params.value[ApiUrlParameterName.CURRENT_PAGE] = index
        return this
    }

    setPerPage(count: MaybeRefOrGetter<number>): this {
        this.builderData._params.value[ApiUrlParameterName.PER_PAGE] = count
        return this
    }

    setPagination(pagination: { page: MaybeRefOrGetter<number>, perPage: MaybeRefOrGetter<number> } | MaybeRefOrGetter<{ page: number, perPage: number }>): this {
        this.builderData._params.value[ApiUrlParameterName.CURRENT_PAGE] = typeof pagination === 'function' || isReactive(pagination) || isRef(pagination) ? computed(() => toValue(pagination).page as number) : toValue(pagination).page
        this.builderData._params.value[ApiUrlParameterName.PER_PAGE] = typeof pagination === 'function' || isReactive(pagination) || isRef(pagination) ? computed(() => toValue(pagination).perPage as number) : toValue(pagination).perPage
        return this
    }

    setIsPaginationEnabled(enabled: MaybeRefOrGetter<boolean>): this {
        this.builderData._params.value[ApiUrlParameterName.IS_PAGINATED] = enabled
        return this
    }

    enablePagination(): this {
        this.builderData._params.value[ApiUrlParameterName.IS_PAGINATED] = true
        return this
    }

    disablePagination(): this {
        this.builderData._params.value[ApiUrlParameterName.IS_PAGINATED] = false
        return this
    }

    setParam(name: string, value: MaybeRefOrGetter<ApiFilterValue>): this {
        this.builderData._params.value[name as keyof typeof this.builderData._params.value] = value
        return this
    }

    onlyAttrs(attrs: keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[]): this {
        const attrsToParse = Array.isArray(attrs) ? attrs : [attrs]
        this.builderData._params.value[ApiUrlParameterName.ONLY] = attrsToParse.join(',')
        return this
    }

    exceptAttrs(attrs: keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[]): this {
        const attrsToParse = Array.isArray(attrs) ? attrs : [attrs]
        this.builderData._params.value[ApiUrlParameterName.EXCEPT] = (attrsToParse as string[]).join(',')
        return this
    }

    whereEquals(column: ApiFilterKey<M>, value: MaybeRefOrGetter<string | number | boolean | null | undefined>, options?: Partial<FilterOptions>): this {
        this.addFilter(column, ApiFilterOperation.EQUAL, value, options)
        return this
    }

    whereNot(column: ApiFilterKey<M>, value: MaybeRefOrGetter<string | number | boolean | null | undefined>, options?: Partial<FilterOptions>): this {
        this.addFilter(column, ApiFilterOperation.NOT_EQUAL, value, options)
        return this
    }

    whereStartsWith(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: MaybeRefOrGetter<string | undefined>, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.STARTS_WITH, value, options)
        return this
    }

    whereEndsWith(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: MaybeRefOrGetter<string | undefined>, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.ENDS_WITH, value, options)
        return this
    }

    whereContains(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: MaybeRefOrGetter<string | undefined>, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.CONTAINS, value, options)
        return this
    }

    whereIn(column: ApiFilterKey<M>, value: MaybeRefOrGetter<string | number | string[] | number[] | undefined>, options?: Partial<FilterOptions>): this {
        this.addFilter(column, ApiFilterOperation.IN, value, options)
        return this
    }

    whereGreaterThan(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: MaybeRefOrGetter<number | string | undefined>, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.GREATER_THAN, value, options)
        return this
    }

    whereGreaterOrEqual(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: MaybeRefOrGetter<number | string | undefined>, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.GREATER_THAN_EQUAL, value, options)
        return this
    }

    whereLessThan(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: MaybeRefOrGetter<number | string | undefined>, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.LESS_THAN, value, options)
        return this
    }

    whereLessOrEqual(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: MaybeRefOrGetter<number | string | undefined>, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.LESS_THAN_EQUAL, value, options)
        return this
    }

    addRouteParam(name: MaybeRefOrGetter<string>): this {
        this.builderData._routeParams.value.push(name)
        return this
    }

    forId(id: MaybeRefOrGetter<string | number | null | undefined>): this {
        this.builderData._id = id ?? null
        return this
    }

    embed<T extends keyof ApiModelEmbeds<M>>(fields: EmbedParametersMap<M, T>['map'][0]): this
    embed<T extends keyof ApiModelEmbeds<M>>(fields: EmbedParametersMap<M, T>['multiple'][0]): this
    embed<T extends keyof ApiModelEmbeds<M>>(field: EmbedParametersMap<M, T>['single'][0], options?: EmbedParametersMap<M, T>['single'][1]): this
    embed<T extends keyof ApiModelEmbeds<M>>(...args: any[]): this {
        const [_fields, _options] = args as EmbedParameters<M, T>

        const options = _options ?? {}

        const fields = Array.isArray(_fields)
            // transform a provided array of fields into an object compatible with the embed ref
            ? _fields.map(field => !Array.isArray(field) ? { field, options } : { field: field[0], options: field[1] })
            : typeof _fields !== 'object'
                // transform a single field
                ? [_fields].map(field => ({ field, options }))
                : (Object.entries(_fields) as [T, EmbedOptions<M, T> | true][])
                    // transform an object
                    .map(([key, value]) => ({
                        field: key,
                        options: value === true ? {} : value,
                    }))

        for (const field of fields) {
            this.builderData._embeds.value[field.field] = field
        }

        return this
    }

    sortByAsc(columns: MaybeRefOrGetter<keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[]>): this {
        if (this.builderData._sort !== null) return this
        // TODO: dispose of effects
        this.builderData._sort = computed(() => {
            const val = toValue(columns)
            return (Array.isArray(val) ? val : [val]).map(column => ({ attr: column, dir: ApiSortDirection.ASCENDING }))
        })
        return this
    }

    sortByDesc(columns: MaybeRefOrGetter<keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[]>): this {
        if (this.builderData._sort !== null) return this
        // TODO: dispose of effects
        this.builderData._sort = computed(() => {
            const val = toValue(columns)
            return (Array.isArray(val) ? val : [val]).map(column => ({ attr: column, dir: ApiSortDirection.DESCENDING }))
        })
        return this
    }

    sortBy(rules: MaybeRefOrGetter<Partial<{ [K in keyof ApiModelAttributes<M>]: MaybeRefOrGetter<ApiSortDirection | boolean | undefined> }>>): this {
        if (this.builderData._sort !== null) return this
        // TODO: dispose of effects
        this.builderData._sort = computed(() => {
            return (Object.entries(toValue(rules)) as [keyof ApiModelAttributes<M>, MaybeRefOrGetter<ApiSortDirection | boolean | undefined>][])
                .filter(([, dir]) => toValue(dir) !== undefined)
                .map(([attr, _dir]) => {
                    const dir = toValue(_dir)
                    return { attr: attr, dir: dir === false ? ApiSortDirection.ASCENDING : dir === true ? ApiSortDirection.DESCENDING : dir as ApiSortDirection }
                })
        })
        return this
    }

}


