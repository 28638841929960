import type { ApiModelAttributes, ApiModelEmbeds } from '../api.model'
import { ApiModel } from '../api.model'
import {
    ApiUrlParameterName,
    type ApiFilter,
    ApiSortDirection,
    type ApiUrlParameterValue,
    ApiFilterOperation,
    createFilter,
    type ApiFilterValue,
    ApiBaseUrlBuilder,
    type ApiEmbedFieldData,
    type ApiUrlParameters,
    type EmbedParametersMap,
    type EmbedParameters,
    type EmbedOptions,
    type ApiFilterKey
} from './api.base-url-builder'
import { joinURL } from 'ufo'
import { Base64 } from 'js-base64'

interface ApiUrlBuilderBaseOptions {}
type ApiUrlBuilderOptions = {
    url: string
} & ApiUrlBuilderBaseOptions

interface FilterOptions {
}

type BuilderData<M extends ApiModel> = {
    _params: Partial<{ [key in (ApiUrlParameterName & string)]: ApiUrlParameterValue }>
    _filters: { f: string[], o: ApiFilter['o'], v: ApiFilter['v'], options: FilterOptions }[]
    _sort: { attr: keyof ApiModelAttributes<M>, dir: ApiSortDirection }[] | null
    _embeds: ApiEmbedFieldData<M>
    // ---
    _url: string
    _routeParams: string[]
    _id: string | number | null | undefined
    // ---
    getBuiltUrl(): string
    getBuiltParams(): Partial<ApiUrlParameters>
}

export class ApiUrlBuilder<M extends ApiModel> extends ApiBaseUrlBuilder<M> {
    private readonly options: ApiUrlBuilderOptions
    private builderData: BuilderData<M>

    constructor(options: ApiUrlBuilderOptions) {
        super()

        this.options = options
        this.builderData = this.generateBuilderData(options)
    }

    private addFilter(column: keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[], operation: ApiFilter['o'], value: ApiFilterValue, options: Partial<FilterOptions> | undefined) {
        this.builderData._filters.push({ f: (Array.isArray(column) ? column : []) as string[], o: operation, v: value, options: options || {} })
    }

    private generateBuilderData(options: ApiUrlBuilderOptions) {
        const data: BuilderData<M> = {
            _params: {},
            _filters: [],
            _sort: null,
            _embeds: {},
            // ---
            _url: options.url,
            _routeParams: [],
            _id: null,
            // ---
            getBuiltUrl: () => {
                return joinURL(
                    data._url,
                    ...data._routeParams,
                    data._id !== null && data._id !== undefined ? `${data._id}` : ''
                )
            },
            getBuiltParams: () => {
                const params: Partial<ApiUrlParameters> = {}

                // keyed parameters
                for (const key in data._params) {
                    const paramValue = data._params[key as keyof typeof data._params]
                    params[key] = typeof paramValue === 'boolean' ? paramValue ? 1 : 0 : paramValue
                }

                // filter parameters
                const filters = data._filters.filter(filter => filter.v !== undefined)
                if (filters.length) {
                    params[ApiUrlParameterName.FILTERS] = Base64.encode(
                        JSON.stringify(
                            filters.map(filter => createFilter(filter.f, filter.o, filter.v))
                        )
                    )
                }

                // sort parameters
                if (data._sort) {
                    params[ApiUrlParameterName.SORT_OPTIONS] = data._sort.map(sort =>
                        `${sort.dir === ApiSortDirection.DESCENDING ? '-' : ''}${sort.attr as string}`
                    ).join(',') || undefined
                }

                // embed parameters
                const embeds: string[] = []
                for (const key in data._embeds) {
                    // only params
                    const only = data._embeds[key]?.options.only ?? []
                    const onlyStr = only.length ? `{${only.join(',')}}` : ''
                    // push the embed
                    embeds.push(`${key}${onlyStr}`)
                }
                if (embeds.length) {
                    params[ApiUrlParameterName.EMBED_FIELDS] = embeds.join(',')
                }

                return params
            },
        }
        return data
    }

    /**
     * Get the data of the builder and reset it.
     * This is used to capture the current state of the builder for a specific fetch service
     * and reset it for other request configurations on the same builder instance.
     * @protected
     */
    protected captureBuilderDataAndReset(): BuilderData<M> {
        const data = this.builderData
        this.builderData = this.generateBuilderData(this.options)
        return data
    }

    // -----------------------------------------------------------------------------------------------------------------
    // Public API

    setPage(index: number): this {
        this.builderData._params[ApiUrlParameterName.CURRENT_PAGE] = index
        return this
    }

    setPerPage(count: number): this {
        this.builderData._params[ApiUrlParameterName.PER_PAGE] = count
        return this
    }

    setPagination(pagination: { page: number, perPage: number }): this {
        this.builderData._params[ApiUrlParameterName.CURRENT_PAGE] = pagination.page
        this.builderData._params[ApiUrlParameterName.PER_PAGE] = pagination.perPage
        return this
    }

    setIsPaginationEnabled(enabled: boolean): this {
        this.builderData._params[ApiUrlParameterName.IS_PAGINATED] = enabled
        return this
    }

    enablePagination(): this {
        this.builderData._params[ApiUrlParameterName.IS_PAGINATED] = true
        return this
    }

    disablePagination(): this {
        this.builderData._params[ApiUrlParameterName.IS_PAGINATED] = false
        return this
    }

    setParam(name: string, value: ApiFilterValue): this {
        this.builderData._params[name as keyof typeof this.builderData._params] = value
        return this
    }

    onlyAttrs(attrs: keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[]): this {
        const attrsToParse = Array.isArray(attrs) ? attrs : [attrs]
        this.builderData._params[ApiUrlParameterName.ONLY] = attrsToParse.join(',')
        return this
    }

    exceptAttrs(attrs: keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[]): this {
        const attrsToParse = Array.isArray(attrs) ? attrs : [attrs]
        this.builderData._params[ApiUrlParameterName.EXCEPT] = (attrsToParse as string[]).join(',')
        return this
    }

    whereEquals(column: ApiFilterKey<M>, value: string | number | boolean | null | undefined, options?: Partial<FilterOptions>): this {
        this.addFilter(column, ApiFilterOperation.EQUAL, value, options)
        return this
    }

    whereNot(column: ApiFilterKey<M>, value: string | number | boolean | null | undefined, options?: Partial<FilterOptions>): this {
        this.addFilter(column, ApiFilterOperation.NOT_EQUAL, value, options)
        return this
    }

    whereStartsWith(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: string | undefined, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.STARTS_WITH, value, options)
        return this
    }

    whereEndsWith(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: string | undefined, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.ENDS_WITH, value, options)
        return this
    }

    whereContains(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: string | undefined, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.CONTAINS, value, options)
        return this
    }

    whereIn(column: ApiFilterKey<M>, value: string | number | string[] | number[] | undefined, options?: Partial<FilterOptions>): this {
        this.addFilter(column, ApiFilterOperation.IN, value, options)
        return this
    }

    whereGreaterThan(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: number | string | undefined, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.GREATER_THAN, value, options)
        return this
    }

    whereGreaterOrEqual(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: number | string | undefined, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.GREATER_THAN_EQUAL, value, options)
        return this
    }

    whereLessThan(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: number | string | undefined, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.LESS_THAN, value, options)
        return this
    }

    whereLessOrEqual(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: number | string | undefined, options?: Partial<FilterOptions>): this {
        this.addFilter(columns, ApiFilterOperation.LESS_THAN_EQUAL, value, options)
        return this
    }

    addRouteParam(name: string): this {
        this.builderData._routeParams.push(name)
        return this
    }

    forId(id: string | number | null | undefined): this {
        this.builderData._id = id ?? null
        return this
    }

    embed<T extends keyof ApiModelEmbeds<M>>(fields: EmbedParametersMap<M, T>['map'][0]): this
    embed<T extends keyof ApiModelEmbeds<M>>(fields: EmbedParametersMap<M, T>['multiple'][0]): this
    embed<T extends keyof ApiModelEmbeds<M>>(field: EmbedParametersMap<M, T>['single'][0], options?: EmbedParametersMap<M, T>['single'][1]): this
    embed<T extends keyof ApiModelEmbeds<M>>(...args: any[]): this {
        const [_fields, _options] = args as EmbedParameters<M, T>

        const options = _options ?? {}

        const fields = Array.isArray(_fields)
        // transform the provided array of fields into an object compatible with the embed object
            ? _fields.map(field => !Array.isArray(field) ? { field, options } : { field: field[0], options: field[1] })
            : typeof _fields !== 'object'
            // transform a single field
                ? [_fields].map(field => ({ field, options }))
                : (Object.entries(_fields) as [T, EmbedOptions<M, T> | true][])
                // transform an object
                    .map(([key, value]) => ({
                        field: key,
                        options: value === true ? {} : value,
                    }))

        for (const field of fields) {
            this.builderData._embeds[field.field] = field
        }

        return this
    }

    sortByAsc(columns: keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[]): this {
        if (this.builderData._sort !== null) return this
        this.builderData._sort = (Array.isArray(columns) ? columns : [columns]).map(column => ({ attr: column, dir: ApiSortDirection.ASCENDING }))
        return this
    }

    sortByDesc(columns: keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[]): this {
        if (this.builderData._sort !== null) return this
        this.builderData._sort = (Array.isArray(columns) ? columns : [columns]).map(column => ({ attr: column, dir: ApiSortDirection.DESCENDING }))
        return this
    }

    sortBy(rules: Partial<{ [K in keyof ApiModelAttributes<M>]: ApiSortDirection | boolean | undefined }>): this {
        if (this.builderData._sort !== null) return this
        this.builderData._sort = (Object.entries(rules) as [keyof ApiModelAttributes<M>, ApiSortDirection | boolean | undefined][])
            .filter(([, dir]) => dir !== undefined)
            .map(([attr, dir]) => {
                return { attr: attr, dir: dir === false ? ApiSortDirection.ASCENDING : dir === true ? ApiSortDirection.DESCENDING : dir as ApiSortDirection }
            })
        return this
    }

}
