import { toast } from 'vue-sonner'

interface NotifyOptions {
    duration: number
}

interface NotifyMessage {
    title: string
    text: string
}

export function useNotifications() {
    const { $i18n } = useNuxtApp()

    /**
     * Shows an error notification.
     * If the message value is nullish, an 'unknown error' message is shown.
     * @param message
     */
    function notifyError(message: string | NotifyMessage | undefined | null = undefined) {
        const title = typeof message === 'string' ? message : message?.title ?? $i18n.t('_core_simploshop.labels.error')
        const description = typeof message === 'string' ? undefined : message?.text ?? $i18n.t('_core_simploshop.labels.unknownError')

        toast.error(title, {
            description: description,
            duration: 5_000,
        })
    }

    /**
     * Shows a success notification.
     * If the message value is nullish, a generic success message is shown.
     * @param message
     */
    function notifySuccess(message: string | NotifyMessage | undefined | null = undefined) {
        const title = typeof message === 'string' ? message : message?.title ?? $i18n.t('_core_simploshop.labels.success')
        const description = typeof message === 'string' ? undefined : message?.text

        toast.success(title, {
            description: description,
            duration: 5_000,
        })
    }

    /**
     * Shows an info notification.
     * @param message
     */
    function notifyInfo(message: string | NotifyMessage) {
        const title = typeof message === 'string' ? message : message.title
        const description = typeof message === 'string' ? undefined : message?.text

        toast.info(title, {
            description: description,
            duration: 5_000,
        })
    }

    function notify(message: string | NotifyMessage, options?: Partial<NotifyOptions>) {
        const title = typeof message === 'string' ? message : message.title
        const description = typeof message === 'string' ? undefined : message.text

        toast(title, {
            description: description,
            duration: options?.duration ?? 5_000,
        })
    }

    return {
        notifyError,
        notifySuccess,
        notifyInfo,
        notify,
    }
}
