import { H3Event, deleteCookie, setCookie } from 'h3'
import { CustomerSessionModel } from '@simploshop-models/customer-session.model'
import { getUrlHostname } from '@sim-api-utils/url'
import useAreCookiesHttpOnly from './useAreCookiesHttpOnly'

export function useAuthCookies(event: H3Event | undefined) {
    const areCookiesHttpOnly = event ? useAreCookiesHttpOnly(event) : false
    const runtimeConfig = useRuntimeConfig()
    const baseUrlHostname = getUrlHostname(runtimeConfig.public.composableApi.baseUrl)

    function setAuthCookies(session: CustomerSessionModel) {
        if (!event || !import.meta.server) return

        // set ACCESS TOKEN cookie
        setCookie(event, runtimeConfig.public.authTokenCookieName, session.accessToken!, {
            httpOnly: areCookiesHttpOnly,
            expires: new Date(session.accessExpirationAt!),
            secure: true,
            domain: baseUrlHostname,
        })

        // set REFRESH TOKEN cookie
        setCookie(event, runtimeConfig.public.refreshTokenCookieName, session.refreshToken!, {
            httpOnly: areCookiesHttpOnly,
            expires: new Date(session.sessionExpirationAt!),
            secure: true,
            domain: baseUrlHostname,
        })

        // set SESSION ID cookie
        setCookie(event, runtimeConfig.public.sessionIdCookieName, `${session.id}`, {
            httpOnly: areCookiesHttpOnly,
            expires: new Date(session.sessionExpirationAt!),
            secure: true,
            domain: baseUrlHostname,
        })
    }

    function deleteAuthCookies() {
        if (!event || !import.meta.server) return

        // remove ACCESS TOKEN cookie
        deleteCookie(event, runtimeConfig.public.authTokenCookieName, {
            httpOnly: areCookiesHttpOnly,
            secure: true,
            domain: baseUrlHostname,
        })

        // remove REFRESH TOKEN cookie
        deleteCookie(event, runtimeConfig.public.refreshTokenCookieName, {
            httpOnly: areCookiesHttpOnly,
            secure: true,
            domain: baseUrlHostname,
        })

        // remove SESSION ID cookie
        deleteCookie(event, runtimeConfig.public.sessionIdCookieName, {
            httpOnly: areCookiesHttpOnly,
            secure: true,
            domain: baseUrlHostname,
        })
    }

    return {
        setAuthCookies,
        deleteAuthCookies,
    }
}
