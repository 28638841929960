import type { ApiModelAttributes, ApiModelEmbeds, ApiModelEmbedValue, ApiModelFilter } from '../api.model'
import { ApiModel } from '../api.model'
import type { NonNullish, RecordKeys } from '../../types/utils'

export type PickAndNullifyOthers<All, Pick> = {
    [K in keyof All]: K extends Pick ? All[K] : null
}

export interface EmbedOptions<M extends ApiModel, K extends keyof ApiModelEmbeds<M>> {
    only: RecordKeys<NonNullish<{ [E in keyof ApiModelEmbeds<M>]: ApiModelEmbeds<M>[E] }[K]>>[]
}

export type EmbedParametersMap<M extends ApiModel, T extends keyof ApiModelEmbeds<M>> = {
    map: [{ [K in T]: EmbedOptions<M, K> | true }]
    multiple: [(T | { [K in T]: [K, EmbedOptions<M, K>] }[T])[]]
    single: [T, Partial<EmbedOptions<M, T>>]
}
// Parameters for the .embed() builder modifier method
export type EmbedParameters<M extends ApiModel, T extends keyof ApiModelEmbeds<M>> = [EmbedParametersMap<M, T>[keyof EmbedParametersMap<M, T>][0], EmbedParametersMap<M, T>[keyof EmbedParametersMap<M, T>][1]]

export enum ApiUrlParameterName {
    CURRENT_PAGE = 'page',
    PER_PAGE = 'per_page',
    IS_PAGINATED = 'paginate',
    SORT_OPTIONS = 'sort',
    FILTERS = 'filters',
    EMBED_FIELDS = 'embed',
    ONLY = 'only',
    EXCEPT = 'except',
}

export enum ApiFilterOperation {
    EQUAL = 'eq',
    CONTAINS = 'contains',
    STARTS_WITH = 'starts_with',
    ENDS_WITH = 'ends_with',
    NOT_EQUAL = 'neq',
    GREATER_THAN = 'gt',
    GREATER_THAN_EQUAL = 'gte',
    LESS_THAN = 'lt',
    LESS_THAN_EQUAL = 'lte',
    IN = 'in',
}

export type ApiFilterValue = string | number | boolean | string[] | number[] | null | undefined

export enum ApiSortDirection {
    ASCENDING = 'asc',
    DESCENDING = 'desc',
}

export type ApiUrlParameterValue = string | number | boolean | null | undefined | string[] | number[] | boolean[]
export type ApiFilter = {
    f: string[]
    o: ApiFilterOperation
    v: ApiFilterValue
}

export type ApiFilterKey<T extends ApiModel> = keyof ApiModelAttributes<T> | keyof ApiModelFilter<T>

export type ApiEmbedFieldData<T extends ApiModel> = { [K in keyof ApiModelEmbeds<T>]?: { field: K, options: Partial<EmbedOptions<T, K>> } }

export interface ApiUrlParameters {
    [ApiUrlParameterName.CURRENT_PAGE]: number
    [ApiUrlParameterName.PER_PAGE]: number
    [ApiUrlParameterName.IS_PAGINATED]: 0 | 1
    [ApiUrlParameterName.SORT_OPTIONS]: string
    [ApiUrlParameterName.FILTERS]: string
    [ApiUrlParameterName.EMBED_FIELDS]: string
    [ApiUrlParameterName.ONLY]: string
    [ApiUrlParameterName.EXCEPT]: string
    [key: string]: any
}

export function createFilter(f: string | string[], o: ApiFilterOperation, v: ApiFilterValue): ApiFilter {
    return { f: Array.isArray(f) ? f : [f], o: o, v: v }
}

export abstract class ApiBaseUrlBuilder<M extends ApiModel> {
    /**
     * Sets the page number to fetch.
     * @param index the number of the page
     */
    abstract setPage(index: number): ApiBaseUrlBuilder<M>

    /**
     * Sets the maximum number of items per page.
     * @param count the count of items per page
     */
    abstract setPerPage(count: number): ApiBaseUrlBuilder<M>

    /**
     * Sets the pagination data for the response (page number and items per page).
     * @param pagination the pagination data
     */
    abstract setPagination(pagination: { page: number, perPage: number }): ApiBaseUrlBuilder<M>

    /**
     * Sets the pagination of the response.
     * @param enabled whether to paginate or not
     */
    abstract setIsPaginationEnabled(enabled: boolean): ApiBaseUrlBuilder<M>

    /**
     * Enables pagination.
     */
    abstract enablePagination(): ApiBaseUrlBuilder<M>

    /**
     * Disables pagination.
     */
    abstract disablePagination(): ApiBaseUrlBuilder<M>

    /**
     * Sets a custom url parameter with a value.
     * Try not to use this method, use the specific methods instead.
     * @param name the name of the url parameter, CASE SENSITIVE
     * @param value the value of the url parameter
     */
    abstract setParam(name: string, value: ApiFilterValue): ApiBaseUrlBuilder<M>

    /**
     * Picks only the specified attributes of the model.
     * Doesn't fetch the whole model, only the specified attributes.
     * @param attrs the attribute(s) to pick
     */
    abstract onlyAttrs(attrs: keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[]): ApiBaseUrlBuilder<M>

    /**
     * Excludes the specified attributes of the model.
     * Fetches the whole model without the specified attributes.
     * @param attrs the attribute(s) to exclude
     */
    abstract exceptAttrs(attrs: keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[]): ApiBaseUrlBuilder<M>

    /**
     * Selects only the data where the value of the specified column is **EQUAL** to the provided value.
     * @param column the key of the column to filter on
     * @param value the value to filter for
     */
    abstract whereEquals(column: ApiFilterKey<M>, value: string | number | boolean | null): ApiBaseUrlBuilder<M>

    /**
     * Selects only the data where the value of the specified column is **NOT EQUAL** to the provided value.
     * @param column the key of the column to filter on
     * @param value the value to filter for
     */
    abstract whereNot(column: ApiFilterKey<M>, value: string | number | boolean | null): ApiBaseUrlBuilder<M>

    /**
     * Selects only the data where the value of specified column(s) **STARTS WITH** the provided value.
     * @param columns the key(s) of the columns to filter on
     * @param value the value to filter for
     */
    abstract whereStartsWith(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: string): ApiBaseUrlBuilder<M>

    /**
     * Selects only the data where the value of specified column(s) **ENDS WITH** the provided value.
     * @param columns the key(s) of the columns to filter on
     * @param value the value to filter for
     */
    abstract whereEndsWith(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: string): ApiBaseUrlBuilder<M>

    /**
     * Selects only the data where the value of specified column(s) **CONTAINS** the provided value.
     * @param columns the key(s) of the columns to filter on
     * @param value the value to filter for
     */
    abstract whereContains(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: string): ApiBaseUrlBuilder<M>

    /**
     * Selects only the data where the value of the specified column **IS WITHIN** the provided values.
     * @param column the key of the column to filter on
     * @param value the value to filter for
     */
    abstract whereIn(column: ApiFilterKey<M>, value: string | number | string[] | number[]): ApiBaseUrlBuilder<M>

    /**
     * Selects only the data where the value of specified column(s) is **GREATER THAN** the provided value.
     * @param columns the key(s) of the columns to filter on
     * @param value the value to compare the column values with
     */
    abstract whereGreaterThan(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: number | string): ApiBaseUrlBuilder<M>

    /**
     * Selects only the data where the value of specified column(s) is **GREATER THAN** or **EQUAL TO**
     * the provided value.
     * @param columns the key(s) of the columns to filter on
     * @param value the value to compare the column values with
     */
    abstract whereGreaterOrEqual(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: number | string): ApiBaseUrlBuilder<M>

    /**
     * Selects only the data where the value of specified column(s) is **LESS THAN** the provided value.
     * @param columns the key(s) of the columns to filter on
     * @param value the value to compare the column values with
     */
    abstract whereLessThan(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: number | string): ApiBaseUrlBuilder<M>

    /**
     * Selects only the data where the value of specified column(s) is **LESS THAN** or **EQUAL TO** the provided value.
     * @param columns the key(s) of the columns to filter on
     * @param value the value to compare the column values with
     */
    abstract whereLessOrEqual(columns: ApiFilterKey<M> | ApiFilterKey<M>[], value: number | string): ApiBaseUrlBuilder<M>

    /**
     * Adds a route parameter to the url.
     * The parameter will be added to the end of the url but before
     * any ID & it is possible to add multiple route parameters as well.
     *
     * Example:
     * ```ts
     * .forId(123)
     * .addRouteParam('foo')
     * .addRouteParam('bar')
     *
     * // Result: '/base-url/foo/bar/123'
     * ```
     * @param name
     */
    abstract addRouteParam(name: string): ApiBaseUrlBuilder<M>

    /**
     * Sets the id of the resource to fetch.
     * @param id the id of the resource
     */
    abstract forId(id: string | number | null | undefined): ApiBaseUrlBuilder<M>

    /**
     * Adds the provided fields(s) to embeds.
     * Subsequent calls will not overwrite the previous embeds but rather add to them.
     * That means that additional fields will be merged with previous ones, however,
     * the same field will be overwritten if it's provided again.
     *
     * ## Filtering embedded resources
     * Attributes of embedded resources can be filtered to only receive the ones specified.
     * This can be done in three ways:
     * - by specifying the `only` option in the options when embedding a single field (Example 1)
     * - by providing an object with the field name as the key and the options as the value,
     *   where the value of `true` means that no options are provided (Example 2)
     * - by providing an array of embeds mixed with nested arrays for embeds with options (Example 3)
     *
     * The third way is the preferred one.
     *
     *
     * @example
     * // Example 1
     * .embed('author', { only: ['first_name'] })
     *
     * // Example 2
     * .embed({
     *   'author': { only: ['first_name'] },
     *   'comments': true,
     * })
     *
     * // Example 3
     * .embed([
     *  'author',
     *  ['comments', { only: ['content'] }],
     * ])
     *
     */
    abstract embed<T extends keyof ApiModelEmbeds<M>>(fields: EmbedParametersMap<M, T>['map'][0]): ApiBaseUrlBuilder<M>
    abstract embed<T extends keyof ApiModelEmbeds<M>>(fields: EmbedParametersMap<M, T>['multiple'][0]): ApiBaseUrlBuilder<M>
    abstract embed<T extends keyof ApiModelEmbeds<M>>(field: EmbedParametersMap<M, T>['single'][0], options?: EmbedParametersMap<M, T>['single'][1]): ApiBaseUrlBuilder<M>

    /**
     * Sorts the data by the values of the given column(s) in **ASCENDING** order.
     * @param columns the column(s) by the values of which to sort the result
     */
    abstract sortByAsc(columns: keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[]): ApiBaseUrlBuilder<M>

    /**
     * Sorts the data by the values of the given column(s) in **DESCENDING** order.
     * @param columns the column(s) by the values of which to sort the result
     */
    abstract sortByDesc(columns: keyof ApiModelAttributes<M> | (keyof ApiModelAttributes<M>)[]): ApiBaseUrlBuilder<M>

    /**
     * Sorts the data by the values of the given column(s) in a provided direction.
     * The direction can be set for each column individually.
     * `ApiSortDirection` enum values must be used, though.
     *
     * (or their boolean equivalents: `true` for `DESCENDING`, `false` for `ASCENDING`)
     * Example:
     * ```
     * {
     *     'first_name': ApiSortDirection.ASCENDING,
     *     'age': ApiSortDirection.DESCENDING,
     * }
     * ```
     * or:
     * ```
     * {
     *     'first_name': true,
     *     'age': false,
     * }
     * ```
     * @param rules the sort rules object
     */
    abstract sortBy(rules: Partial<{ [K in keyof ApiModelAttributes<M>]: ApiSortDirection | boolean | undefined }>): ApiBaseUrlBuilder<M>

}
