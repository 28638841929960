import { defineStore } from 'pinia'
import usePhaseManager from '@core/app/composables/usePhaseManager'
import type { PhaseManagerCartPhase } from '@core-theme/types/cart'
import { CartModel } from '@simploshop-models/cart.model'
import { CartItemModel } from '@simploshop-models/cart-item.model'
import { AddressType } from '@sim-api-enums/address'
import { useEvents } from '@core/app/composables/events'
import { ApiResponseError } from '@composable-api/api.response-error'
import type { UpdateCartAttributes } from '@simploshop-services/Carts.service'
import { PaymentService } from '@sim-api-enums/billing'
import type { MaybePromise } from 'rollup'
import type { Ref } from 'vue'
import { useCartItemsApiService } from '@simploshop-services/CartItems.service'

const CART_ITEMS_EMBEDS = [
    CartItemModel.EMBED_PRODUCT_AVAILABILITY,
    CartItemModel.EMBED_PRODUCT_AMOUNT_UNIT_TRANSLATIONS,
    CartItemModel.EMBED_IMAGE_URL,
    CartItemModel.EMBED_VARIATION_PROPERTIES_NAMES, // selected attributes for properties (product variations)
] as const

interface SubmitOrderOptions {
    onSuccess: () => MaybePromise<any>
}

interface FetchCartOptions {
    _setOnNuxtReady: boolean
}

export const useCartStore = defineStore('cart', () => {
    const appConfig = useAppConfig()

    const { $i18n } = useNuxtApp()
    const events = useEvents()

    const goPayClient = useClientGoPay()

    const { notifyError } = useNotifications()

    // CART
    const cart = ref<InstanceType<typeof CartModel> | null>(null)
    const isCartLoading = ref<boolean>(false)
    const cartPromise = ref<Promise<InstanceType<typeof CartModel> | null> | null>(null)

    /**
     * The order used for the success page.
     */
    const latestOrder = ref<InstanceType<typeof OrderModel> | null>(null)

    const cartItems: Ref<InstanceType<typeof CartItemModel>[] | null> = ref(null)
    const areItemsLoading = computed<boolean>(() => !cartItems.value && areItemsRefreshing.value)
    const areItemsRefreshing = ref<boolean>(false)
    const isShippingMethodLoading = ref<boolean>(false)
    const isPaymentMethodLoading = ref<boolean>(false)

    // TODO: this is a temporary solution until global event bus is implemented
    const submitCallback = ref<Function | null>(null)

    const note = ref<string>('')

    const localePath = useLocalePath()
    // @ts-ignore
    const phaseManager = usePhaseManager(appConfig.cart?.phases as PhaseManagerCartPhase[] ?? [],   {
        requirements: () => ({
            [CartPhase.SHIPPING_AND_PAYMENT]: [
                !!cart.value?.hasItems(),
            ],
            [CartPhase.DELIVERY]: [
                !!cart.value?.hasItems(),
                !!cart.value?.shippingMethod,
                !!cart.value?.paymentMethod,
            ],
            [CartPhase.SUMMARY]: [
                !!latestOrder.value,
            ],
        }),
        onPhaseChange: (newPhase, oldPhase, additionalDetails) => {
            if (additionalDetails.wasForced) {
                // TODO: refactor later
                // if no parent route has the cart middleware, we're not in the cart, do not navigate
                if (!useRoute().matched.some(route => route.meta.middleware === 'cart')) return

                navigateTo(localePath({ name: newPhase.routeName }))
            }
        },
    }) as ReturnType<typeof usePhaseManager<PhaseManagerCartPhase>>

    const currentPhase = phaseManager.phase
    const isLastCartPhase = computed(() => currentPhase.value?.id === CartPhase.DELIVERY)

    const cartsApiService = useCartsApiService()
    const { refresh: _refreshCart, error: cartError } = cartsApiService
        .embed([
            CartModel.EMBED_SHIPPING_OPTION,
        ])
        .forId(() => cart.value?.id)
        .useGet({
            immediate: false,
            watch: false,
            onResponse: (response) => {
                cart.value = response.getItem()
            },
        })
    // TODO: temporary variable to only refresh cart items when all items have been deleted (replace with reactive
    // useFetch refresher like the one above when api service is refactored & support reactive endpoints)
    let deletionPendingForItemCount = 0

    /**
     * A function that will fetch and set the cart.
     *
     * In case of a logged-in user, it will try to fetch the cart by the known cart ID.
     * If there is no known cart ID for the customer (not logged-in or doesn't have a cart),
     * it will try to use the cart ID from the cookie.
     * If there is no known cart ID, it will create a new cart.
     *
     * Error cases are automatically handled (e.g. when the cart is expired).
     *
     * In case the `cartId` parameter is set to `false`, a new cart will be created
     * regardless of the state of the auth store or cookies. This is useful when we
     * want to create a new cart when the user logs out.
     *
     * !! IMPORTANT !!
     * This function should never be used in a try-catch block, because it is
     * throws an error to display in a nuxt error page and that would prevent it from
     * being able to do so.
     *
     * @param cartId the cart id to use as the primary known cart id OR, if set to `false`,
     *              the function will be forced to create a new cart
     * @param options additional options for the function
     */
    async function fetchCart(cartId: string | false | null | undefined = undefined, options?: Partial<FetchCartOptions>) {
        const cartIdCookie = useCartIdCookie()
        const authStore = useAuthStore()

        // ------------------- KNOWN CART ID --------------------
        const loggedInCustomerCartId = authStore._meModel?.latestCartId
        const knownCartId = cartId ?? cartIdCookie.value ?? (
            loggedInCustomerCartId === null
                ? false
                : loggedInCustomerCartId ??
                cartIdCookie.value
        )

        let shouldCreateNewCart = cartId === false || !knownCartId
        let _localCart: InstanceType<typeof CartModel> | null = null

        isCartLoading.value = true

        // ======================= FETCH THE CART =======================
        let resolveFn: (value: InstanceType<typeof CartModel> | null) => void = () => {}

        cartPromise.value = new Promise(resolve => {
            resolveFn = resolve
        })

        if (knownCartId) {
            try {
                const response = await cartsApiService
                    .embed([
                        CartModel.EMBED_SHIPPING_OPTION,
                    ])
                    .exceptAttrs('_links' as any)
                    .forId(knownCartId)
                    .get()

                _localCart = response.getItem()
            } catch (e) {
                let skipErrorLog = false

                // EXPIRED CART
                if (e instanceof ApiResponseError) {

                    if (e.isStatus(403)) {
                        // owner of the cart is different

                        // remove cookie
                        cartIdCookie.value = null
                        shouldCreateNewCart = true
                        skipErrorLog = true
                    } else if (e.isStatus(404)) {
                        // TODO: find out when this happens
                        // cart not found
                        cartIdCookie.value = null
                        shouldCreateNewCart = true
                        skipErrorLog = true
                    }
                }

                if (!skipErrorLog) console.error('[ERROR] cart.ts:', e)
            }
        }

        // ---------------------- GUEST CUSTOMER ----------------------
        if (shouldCreateNewCart) {
            try {
                const response = await cartsApiService
                    .embed([
                        CartModel.EMBED_SHIPPING_OPTION,
                    ])
                    .post()
                _localCart = response.getItem()
            } catch (e) {
                // TODO
                console.error('[ERROR] cart.ts:', e)
            }
        }

        // do not serialize the promise if we're on the server to prevent NON-POJO serialization error
        if (import.meta.server) {
            cartPromise.value = null
        }

        // ======================= SET THE CART =======================

        isCartLoading.value = false

        if (!_localCart) {
            console.error('[ERROR] cart.ts: Cart could not be fetched.')
            // TODO: add retry logic to error.vue
            throw createError({
                statusCode: 500,
                message: $i18n.t('_core_theme.errors.general'),
                fatal: true,
            })
        }

        function setCart() {
            if (!_localCart) return

            cart.value = _localCart
            note.value = ''
            cartIdCookie.value = _localCart.id
            cartItems.value = null

            resolveFn(_localCart)
        }

        if (options?._setOnNuxtReady) {
            onNuxtReady(() => setCart())
        } else {
            setCart()
        }

    } // end of fetchCart

    async function fetchCartIfNotLoaded(options?: Partial<FetchCartOptions>) {
        if (cart.value) return
        // TODO: refactor later
        await fetchCart(undefined, options)
    }

    /**
     * A function that transfers items from a cart to the current cart.
     * This function is used when the user logs in and had items in the cart before logging in.
     *
     * No operation is performed if the current cart is not set already.
     * @param cartId the cart ID to transfer the items from
     */
    async function transferItemsFromCart(cartId: string | null | undefined) {
        await cartPromise.value
        if (!cart.value || !cartId) return
        try {
            // TODO: switch to replace the cart with the one from the response if successful
            await getCartItemsTransferApiService({ targetCartId: cart.value.id! }).post({ source_cart_id: cartId })

            // TODO: remove this cart refresh when the cart is replaced with the one from the response
            await _refreshCart()
        } catch (e) {
            let errorMessage = $i18n.t('_core_theme.errors.cart_error')
            if (e instanceof ApiResponseError) {
                errorMessage = e.getFirstValidationError().message ?? e.getErrorMessage() ?? errorMessage
            }
            notifyError(errorMessage)

            console.error(e)
        }
    }

    const updateCartData = ref<Partial<UpdateCartAttributes>>({})
    const { execute: patchCartWithData, apiError: patchCartError } = cartsApiService
        .embed([
            CartModel.EMBED_SHIPPING_OPTION,
        ])
        .forId(() => cart.value?.id)
        .usePatch(updateCartData, {
            immediate: false,
            watch: false,
            onResponse: (response) => {
                cart.value = response.getItem()
            },
        })

    /**
     * @throws { ApiResponseError } On API error (validation, etc.)
     * @param data
     */
    async function updateCart(data: Partial<UpdateCartAttributes>) {
        await cartPromise.value
        if (!cart.value) return

        updateCartData.value = data
        await patchCartWithData()
        if (patchCartError.value) throw patchCartError.value
    }

    async function updateShipping(data: Partial<Pick<UpdateCartAttributes, 'shipping_method_id' | 'shipping_option_id'>>) {
        isShippingMethodLoading.value = true
        await updateCart({
            shipping_method_id: data.shipping_method_id,
            shipping_option_id: data.shipping_option_id ?? undefined,
        })
        isShippingMethodLoading.value = false
    }

    async function updatePayment(data: Partial<UpdateCartAttributes>) {
        isPaymentMethodLoading.value = true
        await updateCart(data)
        isPaymentMethodLoading.value = false
    }

    const cartAddressesApiService = useCartAddressesApiService({ cartId: () => cart.value?.id })

    /**
     * A function that will update the cart address.
     * The cart address can be either billing or shipping, and they are both on an endpoint separate from the cart.
     * There are 2 possibilities of updating the address:
     * 1. By providing the whole address data (when the user is not signed in)
     * 2. By providing the ID of the address (when the user is signed in)
     * @throws { ApiResponseError } On API error (validation, etc.)
     * @param data
     * @param type
     */
    async function updateCartAddress(data: Parameters<ReturnType<typeof useCartAddressesApiService>['patchShipping']>[0] | number, type: AddressType) {
        await cartPromise.value
        if (!cart.value) return

        if (type === AddressType.BILLING) {
            const response = await cartAddressesApiService.patchBilling(typeof data === 'number' ? { customer_address_id: data } : data)
        } else if (type === AddressType.SHIPPING) {
            const response = await cartAddressesApiService.patchShipping(typeof data === 'number' ? { customer_address_id: data } : data)
        }
    }

    const { execute: _fetchCartItems, apiError: cartItemsError } = useCartItemsApiService({ cartId: () => cart.value?.id })
        .embed(CART_ITEMS_EMBEDS as any)
        .useGet({
            immediate: false,
            watch: false,
            onResponse: (response) => {
                cartItems.value = response.getItems()
            },
        })

    /**
     * Fetch the cart items.
     * If there are items already, it will not fetch them again unless the `force` parameter is set to `true`.
     * @param force
     */
    async function fetchItems(force = false) {
        if (cartItems.value && !force) return
        await cartPromise.value
        if (!cart.value) return

        try {
            areItemsRefreshing.value = true
            await _fetchCartItems()
            if (cartItemsError.value) throw cartItemsError.value
        } catch (e) {
            cartItems.value = null

            let errorMessage = $i18n.t('_core_theme.errors.cart_items_load_error')
            if (e instanceof ApiResponseError) {
                errorMessage = e.getFirstValidationError().message ?? e.getErrorMessage() ?? errorMessage
            }
            notifyError(errorMessage)

            console.error(e)
        } finally {
            areItemsRefreshing.value = false
        }
    }

    async function addToCart(data: { product_id: number, amount: number, variation_id?: number | null | undefined }) {
        await cartPromise.value
        if (!cart.value) return

        try {
            const response = await getCartItemsApiService({ cartId: cart.value.id! })
                .embed(CART_ITEMS_EMBEDS as any)
                .post({
                    [CartItemModel.ATTR_PRODUCT_ID]: data.product_id,
                    [CartItemModel.ATTR_AMOUNT]: data.amount,
                    [CartItemModel.ATTR_PRODUCT_VARIATION_ID]: data.variation_id ?? undefined,
                })
            await Promise.all([
                fetchItems(true),
                _refreshCart(),
            ])

            // emit the cart item added event
            events.emit('cart:item-added', { item: response.getItem()! })   // do not await

        } catch (e) {
            let errorMessage = $i18n.t('_core_theme.errors.add_cart_item_fail')
            if (e instanceof ApiResponseError) {
                errorMessage = e.getFirstValidationError().message ?? e.getErrorMessage() ?? errorMessage
            }
            notifyError(errorMessage)

            console.error(e)
        }
    }

    async function changeItemQuantity(item: CartItemModel | number, quantity: number) {
        await cartPromise.value
        if (!cart.value) return

        const itemId: number = typeof item === 'number' ? item : item.id!

        try {
            const response = await getCartItemsApiService({ cartId: cart.value.id! })
                .embed(CART_ITEMS_EMBEDS as any)
                .forId(itemId)
                .patch({ amount: quantity })

            cartItems.value = cartItems.value?.map((item) => {
                if (item.id === itemId) {
                    // replace with the updated item - or, if the response doesn't contain the item, keep the old one
                    item = response.getItem() ?? item
                }
                return item
            }) ?? null

            await _refreshCart()

        } catch (e) {
            let errorMessage = $i18n.t('_core_theme.errors.cart_item_quantity_change_error')
            if (e instanceof ApiResponseError) {
                errorMessage = e.getFirstValidationError().message ?? e.getErrorMessage() ?? errorMessage
            }
            notifyError(errorMessage)

            console.error(e)
        }
    }

    /**
     * @throws { ApiResponseError } On API error (validation, etc.)
     * @param code
     */
    async function applyVoucher(code: string) {
        await cartPromise.value
        if (!cart.value) return

        const response = await getCartDiscountsApiService({ cartId: cart.value.id! })
            .post(code)

        // update the cart with the new data
        cart.value = response.getItem()
    }

    /**
     * Remove an item from the cart.
     * This function will optimistically remove the item from the cart and then try to remove it from the BE.
     * If the BE deletion fails, the cart items will be reset to the old state.
     * @param item The item to remove from the cart (either the item model itself or its ID)
     */
    async function removeItem(item: CartItemModel | number | null | undefined) {
        await cartPromise.value
        if (!cart.value || !cartItems.value?.length || item === null || item === undefined) return

        const itemId: number = typeof item === 'number' ? item : item.id!

        // store a copy of the current cart items to reset to it if the BE deletion fails
        const oldCartItems = cartItems.value
        // optimistically remove the item from the cart
        cartItems.value = cartItems.value?.filter(item => item.id !== itemId)
        // optimistically reset the cart items count to 0 if the last item is removed
        if (cartItems.value?.length === 0) {
            cart.value.setItemsToZero()
        }

        try {
            deletionPendingForItemCount++   // TODO: temporary

            // remove the item on BE
            await getCartItemsApiService({ cartId: cart.value.id! }).forId(itemId).delete()
        } catch (e) {
            // if the BE deletion fails, reset the cart items to the old state
            cartItems.value = oldCartItems

            let errorMessage = $i18n.t('_core_theme.errors.cart_item_remove_error')
            if (e instanceof ApiResponseError) {
                errorMessage = e.getFirstValidationError().message ?? e.getErrorMessage() ?? errorMessage
            }
            notifyError(errorMessage)

            console.error(e)
        } finally {
            deletionPendingForItemCount--   // TODO: temporary
        }

        // if all items have been deleted, refresh the cart and cart items  // TODO: temporary
        if (deletionPendingForItemCount === 0) {
            // get new cart & cart items from BE to make sure the cart is in sync
            await Promise.all([
                fetchItems(true),
                _refreshCart(),
            ])
        }
    }

    /**
     * TODO
     * @throws ApiResponseError On API error (validation, etc.)
     * @throws Error When the order could not be created correctly
     */
    async function submitOrder(options?: Partial<SubmitOrderOptions>) {
        await cartPromise.value
        if (!cart.value) return

        const shouldContinueSubmitting = await submitCallback.value?.()
        if (shouldContinueSubmitting === false) return

        // @ts-ignore
        const paymentReturnUrl = appConfig.cart?.paymentReturnRouteName
            // @ts-ignore
            ? useFullUrl(localePath({ name: appConfig.cart?.paymentReturnRouteName }))
            : null

        // ============ ORDER CREATION =============
        const orderResponse = await getOrdersApiService().post({
            cart_id: cart.value.id!,
            note: note.value || null,
        })

        latestOrder.value = orderResponse.getItem()

        // TODO: throw a custom error with translated message
        if (!latestOrder.value?.id) {
            throw new Error('Order could not be created.')
        }

        // By this point, the order has already been created successfully, thus
        // we need to refresh the cart in case of any further errors

        try {
            // ================ PAYMENT ================
            // check payment service type & handle payment
            const paymentServiceType = cart.value.paymentMethod?.paymentService ?? null
            if (paymentServiceType === PaymentService.GO_PAY) {
                // GOPAY ---------------------------------------------------------------------------------------------------

                // TODO: throw a custom error with translated message
                if (!paymentReturnUrl) throw new Error('[useCart]: Payment return URL for GoPay is not set in app.config.ts')

                const goPayResponsePromise = getGoPayApiService().post({
                    order_id: latestOrder.value?.id,
                    payload: null,
                    return_url: paymentReturnUrl,
                })

                await goPayClient.checkout(
                    goPayResponsePromise.then(r => r.getItem()?.gateUrl ?? null)
                )

                // ---------------------------------------------------------------------------------------------------------
            }

            await options?.onSuccess?.()
        } finally {
            // ============= CART REFRESH ==============
            // fetch new cart & reset cart items
            const cartResponse = await cartsApiService
                .embed([
                    CartModel.EMBED_SHIPPING_OPTION,
                ])
                .post()
            cart.value = cartResponse.getItem()
            cartItems.value = []
        }
    }

    /**
     * A function that will retry the GoPay payment for the provided order id.
     * @param orderId The order ID to retry the payment for
     */
    async function retryGoPayPayment(orderId: number) {
        // @ts-ignore
        const paymentReturnUrl = appConfig.cart?.paymentReturnRouteName
            // @ts-ignore
            ? useFullUrl(localePath({ name: appConfig.cart?.paymentReturnRouteName }))
            : null

        // TODO: throw a custom error with translated message
        if (!paymentReturnUrl) throw new Error('[useCart]: Payment return URL for GoPay is not set in app.config.ts')


        const goPayResponsePromise = getGoPayApiService().post({
            order_id: orderId,
            payload: null,
            return_url: paymentReturnUrl,
        })

        await goPayClient.checkout(
            goPayResponsePromise.then(r => r.getItem()?.gateUrl ?? null)
        )
    }

    return {
        cart: cart,
        cartPromise: cartPromise,
        isCartLoading: isCartLoading,
        latestOrder: latestOrder,
        note: note,
        items: cartItems,
        areItemsLoading: areItemsLoading,
        areItemsRefreshing: areItemsRefreshing,
        isShippingMethodLoading: isShippingMethodLoading,
        isPaymentMethodLoading: isPaymentMethodLoading,
        submitCallback: submitCallback,
        phase: currentPhase,
        isLastCartPhase: isLastCartPhase,
        phases: phaseManager.phases,
        visiblePhases: computed(() => phaseManager.phases.value.filter(phase => !phase.hidden)),
        isPhaseActive: phaseManager.isPhaseActive,
        isPhaseBeforeActive: phaseManager.isPhaseBeforeActive,
        useIsPhaseActive: phaseManager.useIsPhaseActive,
        isPhaseCompleted: phaseManager.isPhaseCompleted,
        useIsPhaseCompleted: phaseManager.useIsPhaseCompleted,
        switchTo: phaseManager.switchTo,
        canSwitchTo: phaseManager.canSwitchTo,
        useCanSwitchTo: phaseManager.useCanSwitchTo,
        nextPhase: phaseManager.nextPhase,
        fetchCart: fetchCart,
        fetchCartIfNotLoaded: fetchCartIfNotLoaded,
        transferItemsFromCart: transferItemsFromCart,
        updateCart: updateCart,
        updateShipping: updateShipping,
        updatePayment: updatePayment,
        updateCartAddress: updateCartAddress,
        fetchItems: fetchItems,
        changeItemQuantity: changeItemQuantity,
        removeItem: removeItem,
        submitOrder: submitOrder,
        addToCart: addToCart,
        applyVoucher: applyVoucher,
        retryGoPayPayment: retryGoPayPayment,
        requirements: phaseManager.requirements,
    }
})

export function useCartIdCookie() {
    return useCookie<string | null>(useRuntimeConfig().public.cartIdCookieName, {
        maxAge: 2147483647,
        default: () => null,
    })
}
