export default defineNuxtPlugin({
    name: 'global-events',
    parallel: true,
    setup: () => {
        const events = useEvents()
        const cart = useCart()
        const { $i18n } = useNuxtApp()
        const { notifySuccess } = useNotifications()

        // ----- fetch the customer's cart while they're logging in -----
        events.on('customer:logging-in', async ({ me }) => {
            // const oldCartId = useCartIdCookie().value
            await cart.fetchCart(me.latestCartId)
            // await cart.transferItemsFromCart(oldCartId)
        })

        events.on('customer:logged-in', () => {
            // notify the customer about successful login
            notifySuccess($i18n.t('_core_theme.notifications.login_success'))
        })

        // ----- create a new cart for the guest user after they log out -----
        events.on('customer:logging-out', async () => {
            // create a new cart for the guest user
            await useCart().fetchCart(false)
        })

        events.on('customer:logged-out', () => {
            // notify the customer about successful logout
            notifySuccess($i18n.t('_core_theme.notifications.logout_success'))
        })

        // ----- automatically log in the customer after a successful registration -----
        events.on('customer:registering', async ({ credentials }) => {
            // notify the customer about successful registration
            notifySuccess($i18n.t('_core_theme.notifications.registration_success'))

            // automatically log in the customer
            await useAuth().logIn({
                email: credentials.email,
                password: credentials.password,
            })

            // notify the customer about successful login
            notifySuccess($i18n.t('_core_theme.notifications.automatic_login'))

        })
    },
})
