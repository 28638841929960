<template>
    <div class="sim-main-search">
        <BaseUiInput
            ref="inputComponentRef"
            v-model="query"
            :color="color"
            :size="size === 'compact' ? 'sm' : 'md'"
            :placeholder="$t('labels.search')"
            autocomplete="off"
            square
            clearable
            @keyup.enter="isResultsPopupOpen = true"
        >
            <template #leading>
                <IconSearch width="24" height="24" />
            </template>
        </BaseUiInput>

        <!-- RESULTS POPUP -->
        <LazyMainSearchResultsPopup
            v-if="wasResultsPopupMounted && $refs.inputComponentRef"
            v-model="isResultsPopupOpen"
            :anchor="$refs.inputComponentRef as unknown as HTMLElement"
            :query="query"
        />
    </div>
</template>

<script lang="ts" setup>
import type { BaseUiInput } from '#components'
import type { ComponentInstance } from '@core/types/utility'
import type { BaseUiInputColors } from './base/BaseUiInput.vue'

const {
    size = 'compact',
    color = 'gray',
} = defineProps<{
    size?: 'compact' | 'normal'
    color?: BaseUiInputColors
}>()

const query = ref<string>('')

const { is: isResultsPopupOpen, was: wasResultsPopupMounted } = useLazyMount()

watch(query, (val) => {
    isResultsPopupOpen.value = !!val
})

const inputComponentRef = ref<ComponentInstance<typeof BaseUiInput> | null>(null)
// TODO: fix later
// @ts-ignore
const { focused } = useFocusWithin(inputComponentRef)

watch(focused, (isFocused) => {
    if (!isFocused || !query.value) return

    // delay the opening in case the clear button was clicked
    // (not to open it immediately)
    setTimeout(() => {
        if (!focused.value) return
        isResultsPopupOpen.value = true
    }, 100)
})

const route = useRoute()
watch(() => route.path, () => {
    isResultsPopupOpen.value = false
})

function focusInput() {
    // @ts-ignore
    inputComponentRef.value?.focusInput()
}

defineExpose({
    focusInput,
})


</script>

<style lang="scss" scoped>

.sim-main-search {
    position: relative;

    @include more-than(lg) {
        width: min(100%, 32rem);
        max-width: 32rem;   // TODO: fixes a bug where the search input would start out as 100% even though it was bigger
                            // (investigate)
    }
}

</style>
